<template>
  <v-container class="pa-0 fill-height">
    <v-row justify="center" align="center">
      <v-data-table :headers="headers" :items="users" :loading="loading" sort-by="username" class="elevation-1" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Utilisateurs</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
              :background-color="search ? 'blue-grey darken-1' : '#272727'"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" min-width="600px" :max-width="$vuetify.breakpoint.mdAndDown ? '100vw' : '50vw'">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">Créer un utilisateur</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.username" label="Nom d'utilisateur"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.password" type="password" label="Mot de passe"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.email" type="email" label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          v-model.lazy="editedItem.ref_concession_by_id"
                          :items="modified_concessions"
                          return-object
                          item-text="name"
                          chips
                          deletable-chips
                          clearable
                          dense
                          label="Concession"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          v-model.lazy="editedItem.ref_groupe_by_id"
                          multiple
                          :items="modified_groupe"
                          clear-icon
                          return-object
                          item-text="name"
                          chips
                          deletable-chips
                          clearable
                          dense
                          label="Groupe"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          v-model.lazy="editedItem.ref_franchise_by_id"
                          multiple
                          :items="modified_franchise"
                          clear-icon
                          return-object
                          item-text="name"
                          chips
                          deletable-chips
                          clearable
                          dense
                          label="Franchise"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          v-model.lazy="editedItem.ref_vehicule_provider_by_id"
                          :items="all_cardiff"
                          item-text="user"
                          return-object
                          chips
                          deletable-chips
                          clearable
                          dense
                          multiple
                          label="Cardiff"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-autocomplete
                          v-model="editedItem.roles"
                          :items="modified_roles"
                          item-text="text"
                          return-object
                          multiple
                          chips
                          deletable-chips
                          clearable
                          dense
                          label="Privilèges"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.ref_concession_by_id.name="{ item }">
          <router-link
            v-if="item.ref_concession_by_id"
            :to="{ name: 'admin.companies', params: { name: item.ref_concession_by_id.name } }"
            >{{ item.ref_concession_by_id.name }}</router-link
          >
        </template>
        <template v-slot:item.ref_groupe_by_id="{ item }">
          <router-link
            class="mx-1"
            v-for="groupe in item.ref_groupe_by_id"
            :key="groupe.name"
            :to="{ name: 'admin.companies', params: { name: groupe.name } }"
            >{{ groupe.name }}</router-link
          >
        </template>
        <template v-slot:item.ref_franchise_by_id="{ item }">
          <router-link
            class="mx-1"
            v-for="franchise in item.ref_franchise_by_id"
            :key="franchise.name"
            :to="{ name: 'admin.companies', params: { name: franchise.name } }"
            >{{ franchise.name }}</router-link
          >
        </template>
        <template v-slot:item.ref_vehicule_provider_by_id="{ item }">
          <router-link
            class="mx-1"
            v-for="provider in item.ref_vehicule_provider_by_id"
            :key="provider.user"
            :to="{ name: 'admin.VehiculeProviders', params: { user: provider.user } }"
            >{{ provider.user }}</router-link
          >
        </template>
        <template v-slot:item.roles="{ item }">
          <v-chip
            v-for="role in item.roles"
            :key="`${role.text}_${role.type}`"
            class="grey--text text--lighten-5 ma-1"
            :color="role_color[role.type]"
            dark
            label
            small
            >{{ role.text }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Users",

  mixins: [snackBar],

  data: () => ({
    loading: false,

    search: "",
    dialog: false,
    mode: "new",
    headers: [
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Concession", value: "ref_concession_by_id.name" },
      {
        text: "Appartenant au groupe",
        value: "ref_groupe_by_id",
        width: "250px",
      },
      { text: "Franchise", value: "ref_franchise_by_id" },
      { text: "Vehicule provider", value: "ref_vehicule_provider_by_id" },
      { text: "Privilèges", value: "roles", width: "300px", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    editedIndex: -1,
    defaultItem: {
      _id: "",
      username: "",
      ref_concession_by_id: {},
      ref_groupe_by_id: [],
      ref_franchise_by_id: [],
      ref_vehicule_provider_by_id: [],
      roles: [],
      email: "",
      password: "", // item password
    },
    editedItem: {
      _id: "",
      username: "",
      ref_concession_by_id: {},
      ref_groupe_by_id: [],
      groupe: [],
      ref_franchise_by_id: [],
      ref_vehicule_provider_by_id: [],
      roles: [],
      email: "",
      password: "", // item password
    },

    all_roles: [],
    all_companies: [],
    all_cardiff: [],

    role_color: { user: "black", groupe: "orange", franchise: "brown" },
  }),

  async mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getUsers();
      this.getRoles();
      this.getCompanies();
      this.getCardiff();
    },

    async getUsers() {
      this.loading = true;

      try {
        this.users = (await this.$axios.get("/users")).data;

        // refactor users object
        this.users.map((user) => {
          user.ref_groupe_by_id = user?.groupe?.map((el) => el?.ref_groupe_by_id) ?? [];
          user.ref_franchise_by_id = user?.franchise?.map((el) => el?.ref_franchise_by_id) ?? [];
          user.roles = this.formatAllRolesFromUser(user, true); // format roles then filter those who are not of type "user"
        });
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve users infos ... (${error})`);
      } finally {
        this.loading = false;
      }
    },

    formatUserRoles(roles) {
      /**
       *
       * from a user.role == ["admin", "traffic"]
       *
       */
      return roles?.map((r) => ({ type: "user", text: r })) ?? [];
    },

    formatAllRolesFromUser(user, filter_basic) {
      /**
       *
       * from a user.role == ["admin", "traffic"] // user.groupe = [{role: "basic"}] // user.franchise = [{role: "basic"}]
       *
       */

      let roles_formatted = [
        ...(user?.roles?.map((r) => ({ type: "user", text: r })) ?? []),

        ...(user?.groupe?.map((g) => ({
          type: "groupe",
          text: `${g.role.toUpperCase() + "_"}${g.ref_groupe_by_id.name}`,
          value: { groupe: g.ref_groupe_by_id, groupeId: g.ref_groupe_by_id._id, role: g.role },
          // text: `${g.ref_groupe_by_id.name ? g.ref_groupe_by_id.name + " " : ""}${g.role}`,
        })) ?? []),
        ...(user?.franchise?.map((f) => ({
          type: "franchise",
          text: `${f.role.toUpperCase() + "_"}${f.ref_franchise_by_id.name}`,
          value: { franchise: f.ref_franchise_by_id, franchiseId: f.ref_franchise_by_id._id, role: f.role },
          // text: `${f.ref_franchise_by_id.name ? f.ref_franchise_by_id.name + " " : ""}${f.role}`,
        })) ?? []),
      ];
      return filter_basic === true ? this.filterRoleFranchiseBasic(this.filterRoleGroupeBasic(roles_formatted)) : roles_formatted;
      // return roles_formatted;
    },

    filterRoleUser(roles) {
      /**
       *
       * from a user.role == [{type: "user", text: "admin"}, {type: "groupe", text: "supervisor"}]
       *
       */
      return roles.filter((r) => r.type == "user");
    },

    filterRoleGroupeBasic(roles) {
      /**
       * Filter user.role when type == "groupe" && text == "basic"
       */
      return roles?.filter((r) => {
        if (r.type == "groupe" && r.value.role == "basic") return false;
        return true;
      });
    },

    filterRoleFranchiseBasic(roles) {
      /**
       * Filter user.role when type == "franchise" && text == "basic"
       */
      return roles?.filter((r) => {
        if (r.type == "franchise" && r.value.role == "basic") return false;
        return true;
      });
    },

    transformRolesToObjArray(roles, type, text_prefix) {
      /**
       * Transform a role array ( ["admin", "traffic"] ) =====> [{type: "user", text: "admin"}, {type: "user", text: "traffic"}]
       */
      return roles?.map((r) => ({ type, text: `${text_prefix ?? ""}${r}` })) || [];
    },

    transformRolesToStrArr(roles) {
      /**
       * Transform role array ( [{type: "user", text: "admin"}, {type: "user", text: "traffic"}] ) ======> ["admin", "traffic"]
       */
      return roles?.map((r) => r.text) || [];
    },

    async getRoles() {
      try {
        this.all_roles = this.transformRolesToObjArray((await this.$axios.get("/roles")).data?.roles, "user");
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve roles infos ... (${error.response.data.message})`);
      }
    },

    async getCompanies() {
      try {
        this.all_companies = (await this.$axios.get("/all_companies")).data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve companies infos ... (${error.response.data.message})`);
      }
    },

    async getCardiff() {
      try {
        this.all_cardiff = (await this.$axios.get("/all_vehicule_provider")).data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve cardiff infos ... (${error.response.data.message})`);
      }
    },

    async deleteItem(item) {
      try {
        const index = this.users.indexOf(item);
        if (confirm("Are you sure you want to delete this item?")) {
          await this.$axios.delete("/user", { data: { userId: item._id } });
          this.users.splice(index, 1);
        }
      } catch (error) {
        console.error(error);
        if ((error, error.response.data.message))
          this.displayErrorSnack(error.response.data.message ? error.response.data.message : error);
      }
    },

    close() {
      this.dialog = false;
      this.mode = "new";
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedItem.password = ""; // force reset ????
      }, 300);
    },

    async save() {
      if (this.editedIndex > -1) {
        // edit
        await this.handleEdit();
      } else {
        // new
        await this.handleSaveNew();
      }
      this.close();
    },

    async handleSaveNew() {
      let concession = null;
      let cardiff = this.editedItem?.ref_vehicule_provider_by_id?.slice() || [];
      let groupe = this.editedItem?.groupe?.slice() || [];
      let franchise = this.editedItem?.franchise?.slice() || [];

      let user = {
        ...this.editedItem,
        username: this.editedItem.username,
        email: this.editedItem.email,
        roles: this.transformRolesToStrArr(this.filterRoleUser(this.editedItem.roles)),
      };

      try {
        user = { ...user, ...(await this.createUser(user)) };

        concession = await this.addConcessionUser();
        cardiff = await this.addCardiffUser();

        console.log(JSON.stringify(cardiff));
        groupe = await this.addGroupeUser(groupe);
        franchise = await this.addFranchiseUser(franchise);
        console.log(JSON.stringify(groupe));

        user = {
          ...user,
          ref_concession_by_id: concession,
          ref_vehicule_provider_by_id: cardiff,
          groupe: groupe,
          franchise: franchise,
          ref_groupe_by_id: groupe.map((gr) => gr.ref_groupe_by_id),
          ref_franchise_by_id: franchise.map((fr) => fr.ref_franchise_by_id),
        };

        // console.log(user);
        // reset roles
        user.roles = this.transformRolesToStrArr(this.filterRoleUser(user.roles));
        // re set roles based on groupe & franchise
        user.roles = this.formatAllRolesFromUser(user, true);

        /////// TO REMOVE
        this.users.push(user);
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Une erreur s'est produite lors de l'enregistrement d'un nouvel utilisateur (err: ${error})`);
      }
    },

    async createUser(user) {
      let { data } = await this.$axios.post("/register", {
        username: user.username,
        email: user.email,
        roles: user.roles,
        password: this.editedItem.password,
      });

      this.editedItem._id = data._id; // set _id to editedItem for further usage

      return {
        username: data.username,
        roles: this.formatAllRolesFromUser(data, true),
        email: data.email,
        activated: data.activated,
        _id: data._id,
      };
    },

    async addConcessionUser() {
      if (
        this.editedItem.ref_concession_by_id?._id &&
        (this.editedIndex == -1 ||
          this.users[this.editedIndex]?.ref_concession_by_id?._id != this.editedItem?.ref_concession_by_id?._id)
      ) {
        // add a concession
        let res = (
          await this.$axios.put("/admin/user/concession", {
            userId: this.editedItem._id,
            companyId: this.editedItem.ref_concession_by_id._id,
          })
        ).data;

        return res;
        // return await this.addConcessionUser(userId);
      }
      return this.editedItem?.ref_concession_by_id ?? {};
    },

    async deleteConcessionUser() {
      if (this.users[this.editedIndex].ref_concession_by_id?._id && !this.editedItem.ref_concession_by_id?._id) {
        // delete a concession
        await this.$axios.delete("/admin/user/concession", {
          data: { userId: this.editedItem._id },
        });
        return null;
      }
      return this.editedItem.ref_concession_by_id;
    },

    async addGroupeUser(my_groupe = []) {
      for (let i = 0; i < this.editedItem.ref_groupe_by_id.length; i++) {
        if (
          this.editedIndex <= -1 ||
          this.users[this.editedIndex].ref_groupe_by_id.findIndex((gr) => gr._id == this.editedItem.ref_groupe_by_id[i]._id) <= -1
        ) {
          let res = await this.$axios.put("/admin/user/groupe", {
            userId: this.editedItem._id,
            groupeId: this.editedItem.ref_groupe_by_id[i]._id,
            role: this.editedItem?.roles?.find(
              (r) => r.type === "groupe" && r.value.groupeId === this.editedItem.ref_groupe_by_id[i]._id
            )?.value?.role,
          });

          my_groupe.push(res.data);
        }
      }
      return my_groupe;
    },

    async deleteGroupeUser(my_groupe = []) {
      if (!this.users[this.editedIndex].ref_groupe_by_id) return undefined;

      let counter = 0;

      for (let i = 0; i < this.users[this.editedIndex].ref_groupe_by_id.length; i++) {
        if (
          this.editedItem.ref_groupe_by_id.findIndex(
            (groupe) => groupe._id == this.users[this.editedIndex].ref_groupe_by_id[i]._id
          ) <= -1
        ) {
          await this.$axios.delete("/admin/user/groupe", {
            data: {
              userId: this.editedItem._id,
              groupeId: this.users[this.editedIndex].ref_groupe_by_id[i]._id,
            },
          });

          my_groupe?.splice(
            this.users[this.editedIndex].ref_groupe_by_id.findIndex(
              (gr) => gr?._id == this.users[this.editedIndex].ref_groupe_by_id[i]._id
            ) - counter,
            1
          );
          counter++;
        }
      }
      return my_groupe;
    },

    async addFranchiseUser(my_franchise = []) {
      for (let i = 0; i < this.editedItem.ref_franchise_by_id.length; i++) {
        if (
          this.editedIndex <= -1 ||
          this.users[this.editedIndex].ref_franchise_by_id.findIndex(
            (fr) => fr._id == this.editedItem.ref_franchise_by_id[i]._id
          ) <= -1
        ) {
          let res = await this.$axios.put("/admin/user/franchise", {
            userId: this.editedItem._id,
            franchiseId: this.editedItem.ref_franchise_by_id[i]._id,
            role: this.editedItem?.roles?.find(
              (r) => r.type === "franchise" && r.value.franchiseId === this.editedItem.ref_franchise_by_id[i]._id
            )?.value?.role,
          });
          my_franchise.push(res.data);
        }
      }
      return my_franchise;
    },

    async deleteFranchiseUser(my_franchise = []) {
      if (!this.users[this.editedIndex].ref_franchise_by_id) return undefined;

      let counter = 0;

      for (let i = 0; i < this.users[this.editedIndex].ref_franchise_by_id.length; i++) {
        if (
          this.editedItem.ref_franchise_by_id.findIndex(
            (franchise) => franchise._id == this.users[this.editedIndex].ref_franchise_by_id[i]._id
          ) <= -1
        ) {
          let res = await this.$axios.delete("/admin/user/franchise", {
            data: {
              userId: this.editedItem._id,
              franchiseId: this.users[this.editedIndex].ref_franchise_by_id[i]._id,
            },
          });

          my_franchise?.splice(
            this.users[this.editedIndex].ref_franchise_by_id.findIndex(
              (fr) => fr?._id == this.users[this.editedIndex].ref_franchise_by_id[i]._id
            ) - counter,
            1
          );
          counter++;
        }
      }
      return my_franchise;
    },

    async addCardiffUser() {
      if (
        this.editedIndex <= -1 ||
        JSON.stringify(this.editedItem.ref_vehicule_provider_by_id) !==
          JSON.stringify(this.users[this.editedIndex]?.ref_vehicule_provider_by_id)
      ) {
        let res = (
          await this.$axios.put("/admin/user/vehicule_provider", {
            userId: this.editedItem._id,
            vehiculeProviderId: this.editedItem.ref_vehicule_provider_by_id.map((el) => el._id),
          })
        ).data;
        return res;
      }
      return this.editedItem?.ref_vehicule_provider_by_id ?? [];

      // if ()
      //  for (let i = 0; i < this.editedItem.ref_vehicule_provider_by_id.length; i++) {
      //   if (
      //     this.editedIndex <= -1 ||
      //     this.users[this.editedIndex].ref_vehicule_provider_by_id.findIndex(
      //       (fr) => fr._id == this.editedItem.ref_vehicule_provider_by_id[i]._id
      //     ) <= -1
      //   ) {
      //     // let res = await this.$axios.put("/admin/user/franchise", {
      //     //   userId: this.editedItem._id,
      //     //   franchiseId: this.editedItem.ref_franchise_by_id[i]._id,
      //     //   role: this.editedItem?.roles?.find(
      //     //     (r) => r.type === "franchise" && r.value.franchiseId === this.editedItem.ref_franchise_by_id[i]._id
      //     //   )?.value?.role,
      //     // });
      //     // my_franchise.push(res.data);
      //   }
      // }
      // return my_franchise;

      // if (
      //   this.editedItem?.ref_vehicule_provider_by_id?._id &&
      //   (this.editedIndex == -1 ||
      //     !this.users[this.editedIndex]?.ref_vehicule_provider_by_id?._id ||
      //     this.users[this.editedIndex]?.ref_vehicule_provider_by_id?._id != this.editedItem?.ref_vehicule_provider_by_id?._id)
      // ) {
      //   console.log("iinnn");
      //   // add a concession
      //   let res = (
      //     await this.$axios.put("/admin/user/vehicule_provider", {
      //       userId: this.editedItem._id,
      //       vehiculeProviderId: this.editedItem.ref_vehicule_provider_by_id.map((el) => el._id),
      //     })
      //   ).data;
      //   return res;
      // }
      // return this.editedItem?.ref_vehicule_provider_by_id ?? [];
    },

    // async deleteCardiffUser() {
    //   if (
    //     this.users[this.editedIndex].ref_vehicule_provider_by_id?._id &&
    //     (!this.editedItem.ref_vehicule_provider_by_id || !this.editedItem.ref_vehicule_provider_by_id._id)
    //   ) {
    //     // delete a concession
    //     await this.$axios.delete("/admin/user/cardiff", {
    //       data: { userId: this.editedItem._id },
    //     });
    //     return null;
    //   }
    //   return this.editedItem.ref_vehicule_provider_by_id;
    // },

    editItem(item) {
      this.mode = "edit";
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = ""; // force reset ????
      this.dialog = true;
    },

    async handleEdit() {
      let concession = this.editedItem?.ref_concession_by_id ?? {};
      let cardiff = this.editedItem?.ref_vehicule_provider_by_id ?? [];
      let groupe = this.editedItem?.groupe ?? [];
      let franchise = this.editedItem?.franchise ?? [];

      let user = {
        _id: this.editedItem._id,
        userId: this.editedItem._id,
        username: this.editedItem.username,
        roles: this.editedItem.roles,
        email: this.editedItem.email,
        activated: this.editedItem.activated,
      };

      try {
        user = await this.editUser(user);
        await this.changeUserPassword();

        // delete concession
        concession = await this.deleteConcessionUser();
        // delete cardiff
        // cardiff = await this.deleteCardiffUser();
        // delete groupe
        groupe = await this.deleteGroupeUser(groupe);
        // delete franchise
        franchise = await this.deleteFranchiseUser(franchise);

        // add concession
        concession = await this.addConcessionUser();
        // add cardiff
        cardiff = await this.addCardiffUser();
        // add groupe
        groupe = await this.addGroupeUser(groupe);
        // add franchise
        franchise = await this.addFranchiseUser(franchise);

        user = {
          ...user,
          ref_concession_by_id: concession,
          ref_vehicule_provider_by_id: cardiff,
          groupe: groupe,
          franchise: franchise,
          ref_groupe_by_id: groupe.map((gr) => gr.ref_groupe_by_id),
          ref_franchise_by_id: franchise.map((fr) => fr.ref_franchise_by_id),
        };

        // reset roles
        user.roles = this.transformRolesToStrArr(this.filterRoleUser(user.roles));
        // re set roles based on groupe & franchise
        user.roles = this.formatAllRolesFromUser(user, true);

        Object.assign(this.users[this.editedIndex], user);
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    async editUser(user) {
      if (
        this.editedItem.username != this.users[this.editedIndex].username ||
        JSON.stringify(this.filterRoleUser(this.editedItem.roles)) !=
          JSON.stringify(this.filterRoleUser(this.users[this.editedIndex].roles)) ||
        this.editedItem.email != this.users[this.editedIndex].email ||
        this.editedItem.activated != this.users[this.editedIndex].activated
      ) {
        let { data } = await this.$axios.put("/user", {
          ...user,
          roles: this.transformRolesToStrArr(this.filterRoleUser(user.roles)),
        });

        return {
          username: data.username,
          roles: this.formatAllRolesFromUser(data, true),
          email: data.email,
          activated: data.activated,
          _id: data._id,
          // ref_concession_by_id: data.ref_concession_by_id || { name: "" },
          // ref_concession_by_id: data.ref_groupe_by_id || [],
          // ref_concession_by_id: data.ref_franchise_by_id || []
        };
      }
      return {
        username: this.users[this.editedIndex].username,
        roles: this.users[this.editedIndex].roles,
        email: this.users[this.editedIndex].email,
        activated: this.users[this.editedIndex].activated,
        _id: this.users[this.editedIndex]._id,
      };
    },
    async changeUserPassword() {
      if (this.editedItem.password) {
        await this.$axios.put("/admin/user/password", {
          userId: this.editedItem._id,
          newPassword: this.editedItem.password,
        });
      }
    },

    clearConcessionSelection() {
      this.editedItem.ref_concession_by_id = {};
    },

    onGroupeChange() {},
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouvel utilisateur" : "Editer un utilisateur";
    },

    modified_concessions() {
      this.all_companies;
      this.editedItem.ref_groupe_by_id;
      this.editedItem.ref_franchise_by_id;

      return this.all_companies
        .filter((company) =>
          this.editedItem.ref_groupe_by_id
            ? this.editedItem.ref_groupe_by_id.findIndex((groupe) => groupe._id == company._id) <= -1
            : true
        )
        .filter((company) =>
          this.editedItem.ref_franchise_by_id
            ? this.editedItem.ref_franchise_by_id.findIndex((franchise) => franchise._id == company._id) <= -1
            : true
        );
    },

    modified_groupe() {
      this.editedItem.ref_concession_by_id;
      this.editedItem.ref_franchise_by_id;

      // if (this.editedItem.ref_concession_by_id == null) return this.all_companies;

      return this.all_companies
        .filter((company) =>
          this.editedItem.ref_concession_by_id && this.editedItem.ref_concession_by_id._id
            ? company._id != this.editedItem.ref_concession_by_id._id
            : true
        )
        .filter((company) =>
          this.editedItem.ref_franchise_by_id
            ? this.editedItem.ref_franchise_by_id.findIndex((franchise) => franchise._id == company._id) <= -1
            : true
        );
    },
    modified_franchise() {
      this.editedItem.ref_concession_by_id;
      this.editedItem.ref_groupe_by_id;

      // if (this.editedItem.ref_concession_by_id == null) return this.all_companies;

      return this.all_companies
        .filter((company) =>
          this.editedItem.ref_concession_by_id && this.editedItem.ref_concession_by_id._id
            ? company._id != this.editedItem.ref_concession_by_id._id
            : true
        )
        .filter((company) =>
          this.editedItem.ref_groupe_by_id
            ? this.editedItem.ref_groupe_by_id.findIndex((franchise) => franchise._id == company._id) <= -1
            : true
        );
    },

    modified_roles() {
      this.editedItem.ref_groupe_by_id;
      this.editedItem.ref_franchise_by_id;

      return [
        ...this.all_roles,
        // ...this.transformRoles(this.editedItem.ref_groupe_by_id, "groupe", "SUPERVISOR_"),
        // ...this.transformRoles(this.editedItem.ref_franchise_by_id, "franchise", "SUPERVISOR_")
        ...this.editedItem.ref_groupe_by_id.map((gr) => ({
          type: "groupe",
          text: `SUPERVISOR_${gr.name}`,
          value: { groupe: gr, groupeId: gr._id, role: "supervisor" },
        })),
        ...this.editedItem.ref_franchise_by_id.map((fr) => ({
          type: "franchise",
          text: `SUPERVISOR_${fr.name}`,
          value: { franchise: fr, franchiseId: fr._id, role: "supervisor" },
        })),
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
